<script lang="ts">
export default {
    name: 'ForgotPassword',
    setup() {
        definePageMeta({ layout: 'empty' })
        useHead({
            title: 'Mot de passe oublié',
            meta: [
                { name: 'description', content: 'Mot de passe oublié' },
            ],
        })
    },
}
</script>

<template>
    <div>
        <NuxtPage />
    </div>
</template>
